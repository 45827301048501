import React, { useContext, useState} from 'react';
import Context from './Context';
import populae from './populae.svg'
import { PopSvg, popCaption } from './PopSvg'
import { searchObjMultiple } from './PopApiGlobal';



function PopHeader() {
    const { globalDispatch, globalState } = useContext(Context);
    const [ searchValue, setSearchValue ] = useState('');
    const { user, navigation, config } = globalState;
    const [ searchObjTypeId, setSearchObjTypeId ] = useState(navigation.searchObjTypeId);

    function objType(objTypeId) {
        return config.objTypes.find(objType => objType.objTypeId === objTypeId); 
    }
    

    function onChangeSearchValue(o) {
        if (o.target.value.length === 3) {
            let findObjType = config.objTypes.find(objType => objType.objTypeShort === o.target.value.substring(0,3).toUpperCase());
            if (findObjType !== undefined && findObjType.objTypeId !== searchObjTypeId) { 
                setSearchObjTypeId(findObjType.objTypeId);
                setSearchValue('');
            }
            else setSearchValue(o.target.value);
        }
        else setSearchValue(o.target.value);
    }

    function onButtonClick(o) {
        o.preventDefault(); 
        if (searchValue !== '') { 
            navigation.searchObjTypeId = searchObjTypeId;
            searchObjMultiple(searchObjTypeId, objType(searchObjTypeId).attIdName,  searchValue, globalState, globalDispatch); 
        }
        setSearchValue('')
    }    

    function onIconClick(o)    {
        let searchObjTypeI = (config.objTypes.indexOf(objType(searchObjTypeId)) + 1) % config.objTypes.length
        setSearchObjTypeId(config.objTypes[searchObjTypeI].objTypeId);
    }

    return (
        <div className = "pop-header-box">
            <div className="pop-header-title">
                <PopSvg svg="menu" onClick = {() => { navigation.showMenu = !navigation.showMenu; navigation.showFolders = false; globalDispatch( { type: 'update-state', state: globalState }); } }/>
                <img src={populae} alt="icon"></img>
            </div>
            { user.userLogged && 
            <form className="pop-header-search">
                <button type = "submit" onClick = {onButtonClick}>
                    <PopSvg svg = "search"/>
                </button>
                <input id="searchObjects" type="text" placeholder={popCaption(config, "search").captionLong} value ={searchValue} tabIndex = {0} onChange = {onChangeSearchValue}/>
                <PopSvg svg={objType(searchObjTypeId).objTypeSvg} onClick={onIconClick}/>              
            </form> }
        </div> );
}


export default PopHeader;


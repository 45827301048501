import React, { useContext, useRef, useEffect } from 'react';
import useDoubleClick from 'use-double-click';
import Context from './Context';
import { selectObjValues, selectObjRelatedNext } from './PopApiGlobal';
import { PopSvg, popCaption, svgToStr } from './PopSvg';
import { calcRelationMapSize, objRelationsInit } from './PopObjectStruct';
import { FILE_LOCATION, pop } from './PopConst';


function strToPath(path, str, x, y) {
    let steps = str.split(' ');
    do {
        switch (steps[0]) {
            case 'M': path.moveTo(parseInt(steps[1])+x, parseInt(steps[2])+y); steps = steps.slice(3); break;
            case 'L': path.lineTo(parseInt(steps[1])+x, parseInt(steps[2])+y); steps = steps.slice(3); break;
            case 'C': path.bezierCurveTo(parseInt(steps[1])+x, parseInt(steps[2])+y, parseInt(steps[3])+x, parseInt(steps[4])+y, parseInt(steps[5])+x, parseInt(steps[6])+y); steps = steps.slice(7); break;
            case 'Z': path.closePath(); steps = steps.slice(1); break;
            default: break;
        }
      } while (steps.length > 0);
}


function fromToLine(x0, y0, width0, x1, y1, width1) {
    var x2 = x1 - x0;
    var y2 = y1 - y0;
    var a = Math.atan2(y2,x2);
    var d = Math.sqrt(x2 * x2 + y2 * y2);
    var x3 = x0 + (width0+2) * Math.cos(a);
    var y3 = y0 + (width0+2) * Math.sin(a);
    var x4 = x0 + (d-width1-2) * Math.cos(a);
    var y4 = y0 + (d-width1-2) * Math.sin(a);
    return ({ p1: { x: x3, y: y3 }, p2: { x: x4, y: y4 } });
}

function fromToLineArrow(x0, y0, width0, x1, y1, width1) {
    var x2 = x1 - x0;
    var y2 = y1 - y0;
    var a = Math.atan2(y2,x2);
    var d = Math.sqrt(x2 * x2 + y2 * y2);
    var x3 = x0 + (width0+2) * Math.cos(a);
    var y3 = y0 + (width0+2) * Math.sin(a);
    var x4 = x0 + (d-width1-2) * Math.cos(a);
    var y4 = y0 + (d-width1-2) * Math.sin(a);
    var x5 = (x3 + x4) / 2;
    var y5 = (y3 + y4) / 2;
    var x6 = x5 + 7 * Math.cos(a-Math.PI/4);
    var y6 = y5 + 7 * Math.sin(a-Math.PI/4);
    var x7 = x5 + 7 * Math.cos(a+Math.PI/4);
    var y7 = y5 + 7 * Math.sin(a+Math.PI/4);
    
    return ({ p1: { x: x3, y: y3 }, p2: { x: x4, y: y4 }, p3: { x: x5, y: y5 }, p4: { x: x6, y: y6 }, p5: { x: x7, y: y7 } });
}

function fromToLineArrowInv(x0, y0, width0, x1, y1, width1) {
    var x2 = x1 - x0;
    var y2 = y1 - y0;
    var a = Math.atan2(y2,x2);
    var d = Math.sqrt(x2 * x2 + y2 * y2);
    var x3 = x0 + (width0+2) * Math.cos(a);
    var y3 = y0 + (width0+2) * Math.sin(a);
    var x4 = x0 + (d-width1-2) * Math.cos(a);
    var y4 = y0 + (d-width1-2) * Math.sin(a);
    var x5 = (x3 + x4) / 2;
    var y5 = (y3 + y4) / 2;
    var x6 = x5 - 7 * Math.cos(a-Math.PI/4);
    var y6 = y5 - 7 * Math.sin(a-Math.PI/4);
    var x7 = x5 - 7 * Math.cos(a+Math.PI/4);
    var y7 = y5 - 7 * Math.sin(a+Math.PI/4);
    
    return ({ p1: { x: x3, y: y3 }, p2: { x: x4, y: y4 }, p3: { x: x5, y: y5 }, p4: { x: x6, y: y6 }, p5: { x: x7, y: y7 } });
}


function fromToLineCircle(x0, y0, width0, x1, y1, width1) {
    var x2 = x1 - x0;
    var y2 = y1 - y0;
    var a = Math.atan2(y2,x2);
    var d = Math.sqrt(x2 * x2 + y2 * y2);
    var x3 = x0 + (width0+2) * Math.cos(a);
    var y3 = y0 + (width0+2) * Math.sin(a);
    var x4 = x0 + (d-width1-2) * Math.cos(a);
    var y4 = y0 + (d-width1-2) * Math.sin(a);    
    var x5 = (x3 + x4) / 2;
    var y5 = (y3 + y4) / 2;
    var x6 = x5 - 5 * Math.cos(a);
    var y6 = y5 - 5 * Math.sin(a);
    var x7 = x5 + 5 * Math.cos(a);
    var y7 = y5 + 5 * Math.sin(a);

    return ({ p1: { x: x3, y: y3 }, p2: { x: x4, y: y4 }, p3: { x: x5, y: y5 }, p4: { x: x6, y: y6 }, p5: { x: x7, y: y7 } });
// arc(x, y, radius, startAngle, endAngle, counterclockwise)
}

function fromToLine2Circle(x0, y0, width0, x1, y1, width1) {
    var x2 = x1 - x0;
    var y2 = y1 - y0;
    var a = Math.atan2(y2,x2);
    var d = Math.sqrt(x2 * x2 + y2 * y2);
    var x3 = x0 + (width0+2) * Math.cos(a);
    var y3 = y0 + (width0+2) * Math.sin(a);
    var x4 = x0 + (d-width1-2) * Math.cos(a);
    var y4 = y0 + (d-width1-2) * Math.sin(a);    
    var x5 = (x3 + x4) / 2;
    var y5 = (y3 + y4) / 2;
    var x6 = x5 - 8 * Math.cos(a);
    var y6 = y5 - 8 * Math.sin(a);
    var x7 = x5 + 8 * Math.cos(a);
    var y7 = y5 + 8 * Math.sin(a);
    var x8 = x5 - 3 * Math.cos(a);
    var y8 = y5 - 3 * Math.sin(a);
    var x9 = x5 + 3 * Math.cos(a);
    var y9 = y5 + 3 * Math.sin(a);


    return ({ p1: { x: x3, y: y3 }, p2: { x: x4, y: y4 }, p3: { x: x8, y: y8 }, p4: { x: x9, y: y9 }, p5: { x: x6, y: y6 }, p6: { x: x7, y: y7 } });
}

function drawLine2CircleObject(ctx, x0, y0, width0, x1, y1, width1) {
    ctx.lineWidth = 3;
    ctx.lineCap = 'round';
    ctx.strokeStyle = "#06060633";
    var line = fromToLine2Circle(x0, y0, width0, x1, y1, width1);
    
    ctx.beginPath();
    ctx.arc(line.p3.x, line.p3.y, 5, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.arc(line.p4.x, line.p4.y, 5, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(line.p1.x, line.p1.y);
    ctx.lineTo(line.p5.x, line.p5.y);
    ctx.moveTo(line.p6.x, line.p6.y);
    ctx.lineTo(line.p2.x, line.p2.y);
    ctx.stroke();
}

function drawSingleLineObject(ctx, x0, y0, width0, x1, y1, width1) {
    ctx.lineWidth = 3;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'rgba(6,6,6,0.2)';
    var line = fromToLine(x0, y0, width0, x1, y1, width1);
    
    ctx.beginPath();
    ctx.moveTo(line.p1.x, line.p1.y);
    ctx.lineTo(line.p2.x, line.p2.y);
    ctx.stroke();
}

function drawLineCircleObject(ctx, x0, y0, width0, x1, y1, width1) {
    ctx.lineWidth = 3;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'rgba(6,6,6,0.2)';
    var line = fromToLineCircle(x0, y0, width0, x1, y1, width1);
    
    ctx.beginPath();
    ctx.arc(line.p3.x, line.p3.y, 5, 0, 2 * Math.PI);
    ctx.moveTo(line.p1.x, line.p1.y);
    ctx.lineTo(line.p4.x, line.p4.y);
    ctx.moveTo(line.p5.x, line.p5.y);
    ctx.lineTo(line.p2.x, line.p2.y);
    ctx.stroke();
}

function drawLineArrowObject(ctx, x0, y0, width0, x1, y1, width1, invert) {
    var line = {};
    ctx.lineWidth = 3;
    ctx.lineCap = 'round';
    ctx.strokeStyle = 'rgba(6,6,6,0.2)';

    if (invert) line = fromToLineArrowInv(x0, y0, width0, x1, y1, width1); 
    else line = fromToLineArrow(x0, y0, width0, x1, y1, width1); 
    
    ctx.beginPath();
    ctx.moveTo(line.p1.x, line.p1.y);
    ctx.lineTo(line.p2.x, line.p2.y);
    ctx.moveTo(line.p4.x, line.p4.y);
    ctx.lineTo(line.p3.x, line.p3.y);
    ctx.lineTo(line.p5.x, line.p5.y);
    ctx.stroke();
}



function drawLineObject(ctx, x0, y0, width0, x1, y1, width1, attDirection, objTypeId) {
    if (objTypeId !== 1) { 
        drawSingleLineObject(ctx, x0, y0, width0, x1, y1, width1); 
        return;
    }
    switch(attDirection) {
        case 90:  drawLine2CircleObject(ctx, x0, y0, width0, x1, y1, width1); break;
        case 0: drawLineArrowObject(ctx, x0, y0, width0, x1, y1, width1, false); break;       
        case 270: drawLineCircleObject(ctx, x0, y0, width0, x1, y1, width1); break;
        case 180: drawLineArrowObject(ctx, x0, y0, width0, x1, y1, width1, true); break;
        default:
    }
}

function drawNameObject(ctx, objName, x, y) {
    ctx.font = "450 12px Open Sans";
    ctx.fillStyle = "#2B6BA5"; 
    ctx.textAlign = "center";
    ctx.fillText(objName, x, y);
}

function drawNameProfile(ctx, objName, x, y) {
    ctx.font = "450 14px Open Sans";
    ctx.fillStyle = "#2B6BA5";
    ctx.textAlign = "center";
    ctx.fillText(objName, x, y);
}

function drawCircleObject(ctx, x, y) {
    ctx.fillStyle = '#fff'
    var pathMask = new Path2D();
    var svgMark ="M 25 0.5 C 11.476 0.5 0.5 11.476 0.5 25 C 0.5 38.524 11.476 49.5 25 49.5 C 38.524 49.5 49.5 38.524 49.5 25 C 49.5 11.476 38.524 0.5 25 0.5 Z M 51 51 L -1 51 L -1 -1 L 51 -1 L 51 51 Z"
    strToPath(pathMask, svgMark, x, y);
    ctx.fill(pathMask);
    var svgCircle = "M 25 49 C 11.74 49 1 38.26 1 25 C 1 11.74 11.74 1 25 1 C 38.26 1 49 11.74 49 25 C 49 38.26 38.26 49 25 49 Z M 25 -3.5 C 9.268 -3.5 -3.5 9.268 -3.5 25 C -3.5 40.732 9.268 53.5 25 53.5 C 40.732 53.5 53.5 40.732 53.5 25 C 53.5 9.268 40.732 -3.5 25 -3.5 Z"
    var pathCircle = new Path2D();
    strToPath(pathCircle, svgCircle, x, y);
    ctx.fillStyle = '#7CB400';
    ctx.fill(pathCircle); 
}


function drawImageObject(ctx, image, x, y, width) {
    ctx.drawImage(image, x, y, width, width);
    drawCircleObject(ctx, x, y);
}

function drawImageProfile(ctx, image, x, y, width) {
    ctx.drawImage(image, x, y, width, width);
    ctx.fillStyle = '#fff'
    var pathMask = new Path2D();
    var svgMark ="M 50 -1 C 21.848 -1 -1 21.848 -1 50 C -1 78.152 21.848 101 50 101 C 78.152 101 101 78.152 101 50 C 101 21.848 78.152 -1 50 -1 Z M 101 101 L -1 101 L -1 -1 L 101 -1 L 101 101 Z"
    strToPath(pathMask, svgMark, x, y);
    ctx.fill(pathMask);
    var svgCircle = "M 50 -7.5 C 18.26 -7.5 -7.5 18.26 -7.5 50 C -7.5 81.74 18.26 107.5 50 107.5 C 81.74 107.5 107.5 81.74 107.5 50 C 107.5 18.26 81.74 -7.5 50 -7.5 Z M 50 99 C 22.927 99 1 77.072 1 50 C 1 22.927 22.927 1 50 1 C 77.072 1 99 22.927 99 50 C 99 77.072 77.072 99 50 99 Z"
    var pathCircle = new Path2D();
    strToPath(pathCircle, svgCircle, x, y);
    ctx.fillStyle = '#2B6BA5';
    ctx.fill(pathCircle);
}
  

function drawObject(ctx, centerMap, config, objRel, showLabel) {
    let imageObject = new Image();
    if (objRel.objProfile !== null) imageObject.src = FILE_LOCATION + objRel.objProfile;
    else imageObject.src = `data:image/svg+xml;base64,${window.btoa(svgToStr(objRel.objSvg, config, "#bbb"))}`;
        imageObject.onload = () => { 
            let x = centerMap.x + objRel.x;
            let y = centerMap.y + objRel.y;
            drawImageObject(ctx, imageObject, x-(pop.objRel.objectWidth/2), y-(pop.objRel.objectWidth/2), pop.objRel.objectWidth); 
            if (objRel.parent === null) drawLineObject(ctx, centerMap.x, centerMap.y, 60, x, y, 30, objRel.attDirection, objRel.objTypeId)
            else drawLineObject(ctx, centerMap.x + objRel.parent.x, centerMap.y + objRel.parent.y, 30, x, y, 30, objRel.attDirection, objRel.objTypeId);
            drawNameObject(ctx,  objLabel(objRel, showLabel), x, y + 40);
        }
 
}




function drawObjectChilds(ctx, centerMap, config, objRel, showLabel) {
    drawObject(ctx, centerMap, config, objRel, showLabel);
    if (objRel.showChilds) {
         objRel.childs.forEach(objRelChi => {
            drawObjectChilds(ctx, centerMap, config, objRelChi, showLabel);
         })
    } 
}


function drawRelationMap(canvas, mapRelation, config, relations) {
    const { obj, objRelated } = relations;
    
    /*
    let pixRatio = 2;
    canvas.width = width * pixRatio;
    canvas.height = height * pixRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;^*/

    // Setting the context to enable us draw
    const ctx = canvas.getContext('2d');
    ctx.setTransform(1,0,0,1,0,0);
    ctx.scale(pop.objRel.scale, pop.objRel.scale);
    /* ctx.scale(pixRatio, pixRatio); */
   
    //Our first draw
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 5;
    ctx.lineCap = 'round';
    ctx.strokeStyle = '#999';
    const imageProfile = new Image();
    if (obj.objProfile !== null) imageProfile.src = FILE_LOCATION + obj.objProfile ;
    else imageProfile.src = `data:image/svg+xml;base64,${window.btoa(svgToStr("person", config, "#bbb"))}`;
          



    imageProfile.onload = () => { 
        drawImageProfile(ctx, imageProfile, mapRelation.x-(pop.objRel.profileWidth/2), mapRelation.y-(pop.objRel.profileWidth/2), pop.objRel.profileWidth); 
        drawNameProfile(ctx, objLabel(obj, relations.showLabel), mapRelation.x, mapRelation.y + 75);
    };
    objRelated.filter(objRel => objRel.objLevel === 1).forEach(objRel => { 
        drawObjectChilds(ctx, mapRelation, config, objRel, relations.showLabel);
    }) 
}

function objRelShowParent(objRel) {
    if (objRel.parent) {
        objRel.parent.showChilds = true;
     objRelShowParent(objRel.parent); }
}

function objRelHideChilds(objRel) {
    objRel.showChilds = false;
    if (objRel.childs) 
        objRel.childs.forEach(objRelChi => { objRelHideChilds(objRelChi)});     
}


function clickDistance(a, b) {
    return Math.sqrt((a.x-b.x)**2 + (a.y-b.y)**2);
}


function clickOnText(a, b, y) {
    return (Math.abs(b.x - a.x) <= 50) && (Math.abs(b.y - a.y - y) <= 10)  
}

function clickPoint(event, canvas) {
    return { x:  event.changedTouches ? event.changedTouches[0].pageX : event.pageX - canvas.offsetLeft + canvas.parentElement.scrollLeft,
             y:  event.changedTouches ? event.changedTouches[0].pageY : event.pageY - canvas.offsetTop + canvas.parentElement.scrollTop }
}

function pointAdd(a,b) {
    return { x: a.x + b.x, y: a.y + b.y }
}

function objBirthDeath(obj) {
    if (obj.objDate && obj.objDeath) return obj.objDate.substring(0, 4) + ' - ' + obj.objDeath.substring(0, 4);
    if (obj.objDate) return obj.objDate.substring(0, 4);
    if (obj.objDeath) return '- ' + obj.objDeath.substring(0, 4);
    return "";
}


function objShortName(objName) {
    let objNames = objName.split(" ");
    if (objNames.length > 0) return objNames[0];
    return objName.substring(1,10)+"...";
}

function objLabel(obj, showLabel) {
    if (obj.objTypeId === pop.objType.person)
    switch(showLabel) {
        case 1: return obj.objFamily || objShortName(obj.objName); 
        case 2: return objBirthDeath(obj);
        default: return obj.objShort || objShortName(obj.objName);
    }
    else return obj.objName;
}

function PopRelationMap(props) {
      const style = { width: props.mapRelation.width, height: props.mapRelation.height };  
      const { objRelated, obj } = props.relations;

      useDoubleClick({
        onSingleClick: doClick, onDoubleClick: doDoubleClick,
        ref: props.canvasRef,
        latency: 250
      });


        function doDoubleClick(event) {
            const canvas = props.canvasRef.current;
            let p = clickPoint(event, canvas);   

            if (clickDistance(props.mapRelation, p) < 100) 
                selectObjValues(obj.objTypeId, obj.objId, props.globalState, props.globalDispatch);        

            let findObjRel = objRelated.filter(objRel => objRel.shown).find(objRel => (clickDistance(pointAdd(objRel,props.mapRelation),p) < 50));
            if (findObjRel !== undefined) { 
                objRelationsInit(findObjRel, props.globalState);
                selectObjRelatedNext(props.relations.obj, props.globalState, props.globalDispatch);    
            }
        }

        function doClick(event) {
            const canvas = props.canvasRef.current;
            let p = clickPoint(event, canvas);     
            


            if (clickOnText(props.mapRelation, p, 75)) {
                props.relations.showLabel = (props.relations.showLabel + 1) % 3;
                props.globalDispatch( { type: 'update-state', state: props.globalState });
            }
            else if (clickDistance(props.mapRelation, p) < 100) {
                objRelated.forEach(objRel => { objRel.showChilds = false; });
                objRelated.forEach(objRel => { objRel.shown = ((objRel.objLevel === 1) || (objRel.parent && objRel.parent.showChilds === true)); });
                calcRelationMapSize(objRelated, props.mapRelation);
                props.globalDispatch( { type: 'update-state', state: props.globalState });               
            }
            else {
                let findObjRel = objRelated.filter(objRel => objRel.shown).find(objRel => clickOnText(pointAdd(objRel,props.mapRelation),p, 40));
                if (findObjRel !== undefined) {
                    props.relations.showLabel = (props.relations.showLabel + 1) % 3;
                    props.globalDispatch( { type: 'update-state', state: props.globalState });
                } else {
                findObjRel = objRelated.filter(objRel => objRel.shown).find(objRel => (clickDistance(pointAdd(objRel,props.mapRelation),p) < 50));

                if (findObjRel !== undefined && !findObjRel.lastLevel) 
                    if (findObjRel.childs && findObjRel.childs.length > 0) {
                        if (findObjRel.showChilds) {
                            objRelHideChilds(findObjRel);
                        } 
                        else {
                            objRelated.forEach(objRel => { objRel.showChilds = false; });
                            findObjRel.showChilds = true;
                            objRelShowParent(findObjRel);  
                        }
                        objRelated.forEach(objRel => { objRel.shown = ((objRel.objLevel === 1) || (objRel.parent && objRel.parent.showChilds === true)); });
                        calcRelationMapSize(objRelated, props.mapRelation);
                        props.globalDispatch( { type: 'update-state', state: props.globalState });
                    }
                  else 
                     selectObjRelatedNext(findObjRel, props.globalState, props.globalDispatch);    
                }  
            }
        }

      useEffect( () => {
        if (props.mapRelation.width > 0) drawRelationMap(props.canvasRef.current, props.mapRelation, props.config, props.relations);
      } )     

      return (
        <canvas 
            ref={props.canvasRef} 
            style={style}
            width={props.mapRelation.width * pop.objRel.scale}
            height={props.mapRelation.height * pop.objRel.scale} />
      )

}


function PopRelation() {
    const { globalState, globalDispatch } = useContext(Context);
    const { navigation,  config, user, data } = globalState;
    const canvasRef = useRef(null);
  /*  const parentRef = useRef(null); */

  function clickRelationType() {
    data.relations.showType = data.relations.showType === pop.objType.person ? pop.objType.group : pop.objType.person; 
    objRelationsInit(data.relations.obj, globalState);
    selectObjRelatedNext(data.relations.obj, globalState, globalDispatch);    
}


    return (
        navigation.showRelation ?
        <div className = "pop-relation">
            <div className = "pop-relation-header">
                <PopSvg svg="relation"/>
                <h3>{ data.relations.showType === pop.objType.person ? popCaption(config,'relation-map').captionLong : popCaption(config,'relation-map-groups').captionLong}</h3>
                <PopSvg svg={data.relations.showType === pop.objType.person ? "group" : "family"} onClick = {clickRelationType}/>
                <PopSvg svg={navigation.showRelationDetail ? "hide": "show"} onClick= {() => { navigation.showRelationDetail = !navigation.showRelationDetail; globalDispatch( { type: 'update-state', state: globalState });}}/>
                <PopSvg svg="close" onClick = {() => { navigation.showRelation = false; navigation.showRelationDetail = false; globalDispatch( { type: 'update-state', state: globalState });}}/>
            </div>                     
            { navigation.showRelationDetail && 
            <div className = "pop-relation-content" /* ref = {parentRef} */>
                <PopRelationMap canvasRef={canvasRef} mapRelation={navigation.mapRelation} user={user} config={config} relations={data.relations} globalDispatch={globalDispatch} globalState={globalState}/>
            </div>  }
        </div> : null
    );
}

export default PopRelation;
